<template>
  <div>
    <v-container
      fluid
      class="d-flex flex-row align-start pa-0 align-stretch event-container"
    >

        <router-view class="container" :tenant="tenant"></router-view>
    </v-container>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventLayoutBase",
  components: {
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      siteData: siteData,
    };
  },
  async mounted() {

  },
  methods: {
    
  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    
    breadcrumbItems() {
      return [
        { text: 'Admin', exact: true, to: { name: 'adminHome' } },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>

<style lang="scss">
    .width-100p { width: inherit !important;}
    .event-container {
      width: inherit !important;

      .content-container {
        flex-grow: 1;
        min-width: 400px;
      }
    }
    .info-container {
        .label { font-size: 80%; opacity: .8; }
        .value { margin-bottom: 10px; }
        .value pre { white-space: pre-wrap;}
        /*.value:last-child { margin-bottom: 0px; ;}*/
    }

</style>

